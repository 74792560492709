import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import WelcomeLayout from './pages/WelcomeLayout';
import HomeLayout from './pages/HomeLayout';
import AdminLayout from './pages/admin/AdminLayout';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

import { connect } from 'react-redux';
import { getCompanyDataAction } from './redux/actions/AppActions';
import AppReducer from './redux/reducers/AppReducer';

function App({ AppReducer, getCompanyDataAction }) {
  console.log('ENV', process.env.REACT_APP_ENV);

  useEffect(() => {
    /*if(AppReducer.supplier.nit.length > 0){
        history.push('/portal');
        return 0;
    }*/

    getCompanyDataAction();
  }, []);

  return (
    <div>
      { process.env.REACT_APP_ENV !== 'prod' &&
        <div className="watermark-container">
          <div className="watermark">{process.env.REACT_APP_ENV}</div>
        </div>            
      }

      <BrowserRouter basename={ process.env.REACT_APP_ENV !== 'prod' ? (process.env.REACT_APP_ENV === 'test') ? "/rapimerque" : "/" : "/" }>
          <Switch>
              <Route path="/admin" component={AdminLayout} />
              <Route path="/portal" component={HomeLayout} />
              <Route path="/" component={WelcomeLayout} />
          </Switch>
      </BrowserRouter>
      
    </div>
  );
}

const mapStateToProps = ({ AppReducer }) => {
  return {
      AppReducer
  };
};

const mapDispatchToProps = {
  getCompanyDataAction
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

