
import { httpGet, httpGetError } from "../../http";

export const setAppLoadingAction = () => (dispatch) => {
    dispatch({
        type: "SET_LOADING_ON"
    });
}

export const getCompanyDataAction = () => async (dispatch) => {

    dispatch({
        type: "SET_LOADING_ON"
    });

    try{
        const resp = await httpGet('/api/v1/empresa/nit/900160512');
        dispatch({
            type: "SET_COMPANY",
            payload: {
                id: resp.data.nit,
                name: resp.data.razonSocial,
                alterName: resp.data.nombreAlterno,
                vd: resp.data.digitoVerificacion,
                city: resp.data.ciudad,
                dueWarning: resp.data.dueWarning,
                dueBlock: resp.data.dueBlock
            }
        }); 
    } catch (error) {
        console.log(error);        
        dispatch({
            type: "SET_ERROR",
            payload: {
                state: true,
                msg: 'Error.'
            }
        }); 
    } finally {
        dispatch({
            type: "SET_LOADING_OFF"
        });
    }
}

export const getSupplierDataAction = (supplierLogged) => async (dispatch) => {

    dispatch({
        type: "SET_LOADING_ON"
    });

    try{
        const supplierResp = await httpGet('/api/v1/proveedor/nit/' + supplierLogged.nit);
        if(supplierResp.data._id){
            const thirdResp = await httpGet('/api/v1/terceros/nit/' + supplierResp.data.nit);
            dispatch({
                type: "SET_SUPPLIER",
                payload: {
                    _id: supplierResp.data._id,
                    nit: supplierResp.data.nit,
                    razonSocial: supplierResp.data.razonSocial,
                    tel: supplierResp.data.tel,
                    email: supplierResp.data.email,
                    third: {
                        id: thirdResp.data.id,
                        name: thirdResp.data.razonSocial,
                        vd: thirdResp.data.dv,
                        ciudad: thirdResp.data.ciudad,
                        direccion: thirdResp.data.direccion,
                        telefono: thirdResp.data.telefono
                    }
                }
            }); 
        }

        /*dispatch({
            type: "SET_SUPPLIER",
            payload: supplierLogged
        }); */
    } catch (error) {
        console.log(error);        
        dispatch({
            type: "SET_ERROR",
            payload: {
                state: true,
                msg: 'Error.'
            }
        }); 
    } finally {
        dispatch({
            type: "SET_LOADING_OFF"
        });
    }
}

export const cleanSupplierDataAction = () => async (dispatch) => {

    dispatch({
        type: "CLEAN_SUPPLIER"
    });
}