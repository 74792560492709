import React, { useState, useEffect } from 'react';
import CertificadoDetalleView from './CertificadoDetalleView';
import { httpGet } from '../../http';
import { getExpeditionDateByPeriod, getExpeditionDateByYear } from '../../misc';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const CertificadoDetalle = ({ AppReducer, history }) => {

    const [ tipo, setTipo ] = useState({
        descripcion: ''
    });
    const [ rete, setRete ] = useState({
        anno: ''
    });
    const [retencion, setRetencion ] = useState(
        {
            empresa: {
                razonSocial: '',
                nit: '',
                dv: ''
            },
            proveedor: {
                codigo: '',
                deigitoVerificacion: '',
                descripcion: '',
                direccion: '',
                ciudad: ''
            },
            data: [],
            total: {
                base: 0,
                retencion: 0
            },
            tipo: {
                codigo: '',
                titulo: '',
                periodoGravable: '',
                ciudad: '',
                fechaDesde: '',
                fechaHasta: '',
                visible: false
            },
            loading: false,
            error: {}
        }
    );

    const [ request, setRequest ] = useState({
        loading: true,
        error: false,
        errorMsg: ''
    });

    useEffect(() => {

        getRetencion();
        
    }, []);

    const getRetencion = async () => {
        
        setRequest({
            ...request,
            loading: true,
            error: false,
            errorMsg: ''
        });

        try{
            const tipoRetencion = history.location.state.tipo;
            const rete = history.location.state.retencion;
            const proveedor = AppReducer.supplier
            console.log(proveedor);
            console.log(rete);
            let resp = undefined;
            if(rete.periodo){
                resp = await httpGet('/api/v1/retenciones/detalle/periodo/' + tipoRetencion.cuenta + '/' + rete.id + '/' + proveedor.nit);
            } else if(rete.mes){
                resp = await httpGet('/api/v1/retenciones/detalle/mes/' + tipoRetencion.cuenta + '/' + rete.id + '/' + proveedor.nit);
            } else {
                resp = await httpGet('/api/v1/retenciones/detalle/anno/' + tipoRetencion.cuenta + '/' + history.location.state.retencion.anno + '/' + proveedor.nit);
            }
            const respTercero = await httpGet('/api/v1/proveedor/tercero/' + proveedor.nit);
            if(resp){
    
                let totalBase = 0;
                let totalRetencion = 0;
    
                for( const reg of resp.data){
                    totalBase = totalBase + reg.base;
                    totalRetencion = totalRetencion + reg.retencion;
                }
    
                const total = {
                    base: totalBase,
                    retencion: totalRetencion
                }
    
                const tipo = {
                    descripcion: tipoRetencion.descripcion,
                    titulo: tipoRetencion.titulo,
                    periodoGravable: ( rete.periodo || rete.mes ) ? rete.descripcion + ' de ' + rete.anno : rete.anno,
                    ciudad: tipoRetencion.ciudad,
                    modo: ( rete.periodo || rete.mes ) ? true : false
    
                }
    
                const empresa = {
                    razonSocial: AppReducer.company.name,
                    nit: AppReducer.company.id,
                    digitoVerificacion: AppReducer.company.vd,
                    representanteLegal: ''
                }
    
                setRetencion({
                    ...retencion,
                    empresa: empresa,
                    proveedor: respTercero.data[0],
                    data: resp.data,
                    total: total,
                    tipo: tipo,
                    fechaDeExpedicion: rete.periodo ? getExpeditionDateByPeriod(rete.id) : getExpeditionDateByYear(rete.anno)
                });
    
                setTipo(tipoRetencion);

                setRequest({
                    ...request,
                    loading: false,
                    error: false,
                    errorMsg: ''
                });
            }
        } catch(error) {
            setRequest({
                ...request,
                loading: false,
                error: error,
                errorMsg: ''
            });

            console.log(error);
        }
    }

    return(
        <>
         {/*<CertificadoDetalleView AppReducer={AppReducer} retencion={retencion} onClick={handleClick} onChange={handleChange} />*/}
         <CertificadoDetalleView AppReducer={AppReducer} history={history} request={request} retencion={retencion} tipo={tipo} />
        </>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(CertificadoDetalle);
