import React, { useState, useEffect } from 'react';
import { PDFViewer, Document, Page } from '@react-pdf/renderer'
import Button from '../../components/button/Button';
import EgresoPDFView from './EgresoPDFView';
import { getPDF } from '../../pdf';

const EgresoPDF = ({ history }) => {

    const [ PDFDocument, setPDFDocument ] = useState('');
    
    useEffect(() => {

        if(!history.location.state.egreso){
            history.push('/portal');
            return 0;
        }

        const ini = async () => {
            if (history.location.state.egreso) {
                const docPDF = <EgresoPDFView egreso={ history.location.state.egreso } />
                const doc = {
                    encabezado: {
                        co: history.location.state.egreso.encabezado.idCo,
                        tipoDoc: history.location.state.egreso.encabezado.tipoDoc,
                        numDoc: history.location.state.egreso.encabezado.numDoc
                    }
                }
                const pdf = await getPDF(doc, docPDF);
                setPDFDocument(pdf);
            }
        }
        ini();

    }, []);

    /*return(
        <>
            <div>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <EgresoPDFView egreso={ history.location.state.egreso } />
            </PDFViewer>
        </>
    );*/

    return(
        <>
            <div style={{ width: '150px' }}>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <object width="100%" style={{ height: '100vh' }} data={PDFDocument} type="application/pdf">   </object>
        </>
    );
}

export default EgresoPDF;