import React, { useState, useEffect } from "react";
import CertificadoVisualizacionView from "./CertificadoVisualizacionView";
import { httpGet } from "../../http";

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const CertificadoVisualizacion = ({ AppReducer, history }) => {
    
    const { tipo } = history.location.state;

    const [ retenciones, setRetenciones ] = useState([]);
    const [ isChecked1, setCheked1] = useState(true);
    const [ isChecked2, setCheked2] = useState(false);
    const [ isChecked3, setCheked3] = useState(false);
    const [ request, setRequest ] = useState({
        loading: true,
        error: false,
        errorMsg: ''
    });

    useEffect(() => {
        getRetencion(true);
    }, []);

    const getRetencion = async (modo) => {
        setRequest({
            ...request,
            loading: true,
            error: false,
            errorMsg: ''
        });

        try{
            let resp = null;
            if(modo == 1){
                resp = await httpGet('/api/v1/retenciones/meses/' + tipo.cuenta + '/' + AppReducer.supplier.nit);
            } else if (modo == 2) {
                resp = await httpGet('/api/v1/retenciones/periodos/' + tipo.cuenta + '/' + AppReducer.supplier.nit);
            } else if (modo == 3) {
                resp = await httpGet('/api/v1/retenciones/annos/' + tipo.cuenta + '/' + AppReducer.supplier.nit);
            }
            
            if(resp){
                setRetenciones(resp.data);
            }

            setRequest({
                ...request,
                loading: false,
                error: false,
                errorMsg: ''
            });
        } catch(error){
            console.log(error);
            setRequest({
                ...request,
                loading: false,
                error: true,
                errorMsg: ''
            });
        }
    }

    const handleChange = (e) => {
        console.log(e.target.value);
        if(e.target.value === '1'){
            setCheked1(true);
            setCheked2(false);
            setCheked3(false);
            getRetencion(1);
        } else if(e.target.value === '2') {
            setCheked1(false);
            setCheked2(true);
            setCheked3(false);
            getRetencion(2);
        } else if(e.target.value === '3') {
            setCheked1(false);
            setCheked2(false);
            setCheked3(true);
            getRetencion(3);
        }
    }
    
    return(
        <CertificadoVisualizacionView
            tipo = {tipo}
            request = {request}
            isChecked1 = {isChecked1}
            isChecked2 = {isChecked2}
            isChecked3 = {isChecked3}
            onChange = {handleChange}
            retenciones = {retenciones}
        />
    )
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(CertificadoVisualizacion);