import React, { useState, useEffect } from 'react';
import { PDFViewer, Document, Page } from '@react-pdf/renderer'
import Button from '../../components/button/Button';
import DocumentoFIDetallePDFView from './DocumentoFIDetallePDFView';
import { getPDF } from '../../pdf';

const DocumentoFIDetallePDF = ({ history }) => {

    const [ PDFDocument, setPDFDocument ] = useState('');

    useEffect(() => {

        if(!history.location.state.documento){
            history.push('/portal');
            return 0;
        }

        const ini = async () => {
            if (history.location.state.documento) {
                const docPDF = <DocumentoFIDetallePDFView documento={history.location.state.documento} />;
                const pdf = await getPDF({ encabezado: { co: history.location.state.documento.encabezado.idCo, tipoDoc: history.location.state.documento.encabezado.tipoDoc, numDoc: history.location.state.documento.encabezado.numDoc } }, docPDF);
                setPDFDocument(pdf);
            }
        }
        ini();

    }, []);

    {/*return(
        <>
            <div>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <DocumentoFIDetallePDFView documento={ history.location.state.documento } />
            </PDFViewer>
        </>
    );*/}

    return(
        <>
            <div style={{ width: '150px' }}>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <object width="100%" style={{ height: '100vh' }} data={PDFDocument} type="application/pdf">   </object>
        </>
    );
}

export default DocumentoFIDetallePDF;