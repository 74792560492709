import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import Button from '../../components/button/Button';
import DocumentoCMDetallePDFView from './DocumentoCMDetallePDFView';
import { getPDF } from '../../pdf';

const DocumentoCMDetallePDF = ({ history }) => {

    const [ PDFDocument, setPDFDocument ] = useState('');

    useEffect(() => {

        if(!history.location.state.documento){
            history.push('/portal');
        }
        
        const ini = async () => {
            if (history.location.state.documento) {
                const docPDF = <DocumentoCMDetallePDFView documento={history.location.state.documento} />;
                const pdf = await getPDF(history.location.state.documento, docPDF);
                setPDFDocument(pdf);
            }
        }
        
        ini();
    }, []);

    /*return(
        <>
            <div>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <DocumentoCMDetallePDFView documento={history.location.state.documento} />
            </PDFViewer>
        </>
    );*/

    return(
        <>
            <div style={{ width: '150px' }}>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <object width="100%" style={{ height: '100vh' }} data={PDFDocument} type="application/pdf">   </object>
        </>
    );
}

export default DocumentoCMDetallePDF;