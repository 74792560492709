import React from "react";
import './Storage.css'

const StorageView = () => {
    return(
        <>
        </>
    );
}

export default StorageView;