import React, { useState, useEffect } from 'react';
import { httpGet } from '../../http';
import DocumentoComercialDetalleView from './DocumentoComercialDetalleView';
import { createPDF } from '../../pdf';
import DocumentoCMDetallePDFView from '../DocumentoCMDetallePDF/DocumentoCMDetallePDFView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const DocumentoComercialDetalle = ({ history, AppReducer }) => {

    const [documento, setDocumento ] = useState(
        {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: {
                co: '',
                coDescripcion: '',
                tipoDoc: '',
                numDoc: '',
                fechaDoc: '',
                detalle: '',
                terceroDescripcion: '',
                numDocProv: '',
                totalBruto: '',
                descuentos: '',
                impuestos: '',
                totalNeto:''
            },
            detalles: [],
            loading: true,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        if(!history.location.state.documento){
            history.push('/portal');
            return 0;
        }
        
        getDocumentoDetalle(history.location.state.documento);

    }, []);

    const getDocumentoDetalle = async (doc) => {
        setDocumento({
            ...documento,
            loading: true
        });
                
        const encabezado = await httpGet('/api/v1/egresos/documento/' + doc.co + '/' + doc.tipoDoc + '/' + doc.numDoc);
        const detalles = await httpGet('/api/v1/egresos/documento-detalle/' + doc.co + '/' + doc.tipoDoc + '/' + doc.numDoc);
        //const co = await httpGet('/ProveedoresWS/api/CentroOperacion/codigo?emp=' + sessionStorage.getItem('companyId') + '&cod=' + doc.co);

        console.log(encabezado);
        console.log(detalles);

        if((encabezado.data !== null) && (encabezado.data !== undefined)){
            setDocumento({
                ...documento,
                encabezado: encabezado.data[0],
                detalles: detalles.data,
                loading: false
            });
        }
        
    }

    
    const handleDownloadPDF = async () => {
        const doc = { 
            empresa: AppReducer.company, 
            proveedor: AppReducer.supplier, 
            encabezado: documento.encabezado, 
            detalles: documento.detalles 
        }
        const docPDF = <DocumentoCMDetallePDFView documento={doc} />;
        const pdf = await createPDF(doc, docPDF);
        await pdf.save(doc.encabezado.co + '-' + doc.encabezado.tipoDoc + '-' + doc.encabezado.numDoc + '.pdf');
    }

    return(
        <>{
            <DocumentoComercialDetalleView AppReducer={AppReducer} documento={documento} downloadPDFFile={handleDownloadPDF} />
        }</>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(DocumentoComercialDetalle);