import React, { useState, useEffect} from 'react';
import { httpGet } from '../../http';
import { pdf } from '@react-pdf/renderer';
import PDFMerger from 'pdf-merger-js';
import DocumentoFinancieroDetalleView from './DocumentoFinancieroDetalleView';
import { createPDF } from '../../pdf';
import DocumentoFIDetallePDFView from '../DocumentoFIDetallePDF/DocumentoFIDetallePDFView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const DocumentoFinancieroDetalle = ({ history, AppReducer }) => {

    const [documento, setDocumento ] = useState(
        {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: {
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                fechaVcto: '',
                valor: '',
                debitos: '',
                creditos: '',
                detalle: '',
            },
            detalles: [],
            retenciones: [],
            totalRetenciones: 0,
            loading: true,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        if(!history.location.state.documento){
            history.push('/portal');
            return 0;
        }
        
        getdocumentoDetalle(history.location.state.documento);

    }, []);

    const getdocumentoDetalle = async (doc) => {
        setDocumento({
            ...documento,
            loading: true
        });
        
        const encabezado = await httpGet('/api/v1/notas/cg/encabezado/' + doc.coMov + '/' + doc.tipoDoc + '/' + doc.numDoc);
        const detalles = await httpGet('/api/v1/egresos/documentos/' + doc.coMov + '/' + doc.tipoDoc + '/' + doc.numDoc);

        setDocumento({
            ...documento,
            encabezado: encabezado.data[0],
            detalles: detalles.data,
            loading: false
        });
    }

    const generarDocumentoDetallePdf = async () => {
        let newDocumento = {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: documento.encabezado,
            detalles: documento.detalles
        }

        console.log(newDocumento);

        history.push({ pathname: '/portal/documento-fi-detalle-pdf', state: { documento: newDocumento } });
    }

    const handleDownloadPDF = async () => {
        const doc = { 
            empresa: AppReducer.company, 
            proveedor: AppReducer.supplier, 
            encabezado: documento.encabezado, 
            detalles: documento.detalles 
        }
        const docPDF = <DocumentoFIDetallePDFView documento={doc} />;
        const pdf = await createPDF({ encabezado: { co: doc.encabezado.idCo, tipoDoc: doc.encabezado.tipoDoc, numDoc: doc.encabezado.numDoc } }, docPDF);
        await pdf.save(doc.encabezado.idCo + '-' + doc.encabezado.tipoDoc + '-' + doc.encabezado.numDoc + '.pdf');
    }

    return(
        <>{
            <DocumentoFinancieroDetalleView AppReducer={AppReducer} documento={documento} generarDocumentoDetallePdf={generarDocumentoDetallePdf} downloadPDFFile={handleDownloadPDF} />
        }</>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(DocumentoFinancieroDetalle);
