import { httpGet, getFile } from '../http';
import { pdf } from '@react-pdf/renderer';
import PDFMerger from 'pdf-merger-js';

const getSupportDocuments = async (doc) => {
    try {
        const resp = await httpGet('/api/v1/soportes/doc/' + doc.encabezado.co + doc.encabezado.tipoDoc + doc.encabezado.numDoc);
        console.log('RESP', resp);
        return resp.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}



export const createPDF = async (doc, docPDF) => {
    console.log('DOC', doc);
    const supportDocuments = await getSupportDocuments(doc);
    const asPdf = pdf();
    asPdf.updateContainer(docPDF);

    //let appendixBlob = await getFile();
    let initialBlob = await asPdf.toBlob();

    let merger = new PDFMerger();

    await merger.add(initialBlob);
    
    for (const sd of supportDocuments) {
        const appendixBlob = await getFile(sd.nombreArchivo);
        await merger.add(appendixBlob);
    }
    
    return merger;
}

export const getPDF = async (doc, docPDF) => {
    
    const pdf = await createPDF(doc, docPDF);
    const mergedPdf = await pdf.saveAsBlob();
    console.log('merge', mergedPdf);

    return URL.createObjectURL(mergedPdf);
}