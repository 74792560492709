import React, { useState, useEffect } from "react";
import MenuView from "./MenuView";

import { connect } from 'react-redux';
import MenuReducer from '../../redux/reducers/AppReducer';

const Menu = ({ MenuReducer, onMenuOptionClick, history }) => {

    const [ menu, setMenu ] = useState({
        menuClass: 'menu',
        menuOptionText: 'menu-option-text'
    });

    useEffect(() => {
        
    }, [MenuReducer.menuActive]);

    useEffect(() => {
        if (MenuReducer.menuActive) {
            setMenu({
                ...menu,
                menuClass: 'menu-active',
                menuOptionText: 'menu-option-text-active'
            });
        } else {
            setMenu({
                ...menu,
                menuClass: 'menu',
                menuOptionText: 'menu-option-text'
            });
        }
    }, [MenuReducer.menuActive]);

    const handleMouseOver = () => {
        setMenu({
            ...menu,
            menuClass: 'menu-active',
            menuOptionText: 'menu-option-text-active'
        });
    }

    const handleMouseLeave = () => {
        setMenu({
            ...menu,
            menuClass: 'menu',
            menuOptionText: 'menu-option-text'
        });
    }

    return(
        <MenuView menu={menu} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onMenuOptionClick={onMenuOptionClick} />
    );
}

const mapStateToProps = ({ MenuReducer }) => {
    return {
        MenuReducer
    };
};

export default connect(mapStateToProps)(Menu);
