import React, { useState, useEffect } from 'react';
import { httpGet } from '../../http';
import CentrosOperacionView from './CentrosOperacionView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const CentrosOperacion = ({ AppReducer, history }) => {

    const { path } = history.location.state;

    const [co, setCo ] = useState(
        {
            data: [],
            loading: false,
            error: {}
        }
    );

    useEffect(() => {

        getCos();

    }, []);

    const getCos = async () => {
        setCo({
            ...co,
            loading: true
        });
        
        const cos = await httpGet('/api/v1/centro-operacion/visible');

        if((cos !== null) && (cos !== undefined)){
            setCo({
                ...co,
                data: cos.data,
                loading: false
            });
        }
        
    }

    return(
        <>
            <CentrosOperacionView AppReducer={AppReducer} co={co} path={path} />
        </>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(CentrosOperacion);
