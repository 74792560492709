import React from "react";
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { formatNumber } from '../../misc';
import './CertificadoVisualizacion.css';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import Card from '../../components/Card';

const TaxCard = ({ tax, isChecked1, isChecked2 }) => {
    return(
        <Card>
            <div className='card-fields-container egresos-cards-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>AÑO:</div>
                    <div className='card-field-data'>{ tax.anno }</div>
                </div>
                { isChecked2 ?
                    <div className='card-field-container'>
                        <div className='card-field-title'>PERIODO:</div>
                        <div>{ tax.descripcion }</div>
                    </div>
                : isChecked1 &&
                    <div className='card-field-container'>
                        <div className='card-field-title'>MES:</div>
                        <div>{ tax.descripcion }</div>
                    </div>
                }
                <div className='card-field-container'>
                    <div className='card-field-title'>BASE:</div>
                    <div>${ formatNumber(tax.base) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>RETENCIÓN</div>
                    <div>${ formatNumber(tax.retencion) }</div>
                </div>
            </div>
        </Card>
    );
}

const CertificadoVisualizacionView = ({ tipo, request, isChecked1, isChecked2, isChecked3, onChange, retenciones }) => {
    return(
        <div className='page-container'>
            <Title>{ tipo.titulo }</Title>
            
            <div className='page-body'>
                {
                    request.loading ?
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : request.error ?
                    <div>
                        <Alert style={{ width: '100%', marginLeft: '40px', marginTop: '50px', marginBottom: '50px', textAlign: 'center' }} variant='success'>Ocurrio un error al cargar la información</Alert>
                    </div>
                    :
                    <>
                        <br/>
                        <div>
                            <div className="retencion-radio-title">
                                Modo de visualización:
                            </div>
                            <div className="retencion-radio-container" >
                                <div><input className="retencion-radio-option" type="radio" id="mode1" name="mode" value="1" checked={isChecked1} onChange={onChange} /><label htmlFor="mode1">Mensual</label></div>
                                <div><input className="retencion-radio-option" type="radio" id="mode2" name="mode" value="2" checked={isChecked2} onChange={onChange} /><label htmlFor="mode2">Bimestral</label></div>
                                <div><input className="retencion-radio-option" type="radio" id="mode3" name="mode" value="3" checked={isChecked3} onChange={onChange} /><label htmlFor="mode3" >Anual</label></div>
                                
                            </div>
                        </div>
                        <br />
                        <div className='desktop-view'>
                            <table className='info-table tabla-retencion'>
                                <thead>
                                    <tr>
                                        <th>AÑO</th>
                                        { isChecked2 ? <th>PERIODO</th> : isChecked1 && <th>MES</th> }
                                        <th>BASE</th>
                                        <th>RETENCION</th>
                                        <th>VER DETALLE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { 
                                        retenciones.map(
                                            (retencion, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{ retencion.anno }</td>
                                                        { (isChecked2 || isChecked1) && <td>{ retencion.descripcion }</td> }
                                                        <td>${ formatNumber(retencion.base) }</td>
                                                        <td>${ formatNumber(retencion.retencion) }</td>
                                                        <td><div className='view-icon-container'><Link to={{ pathname: "/portal/certificado-detalle", state: { tipo: tipo, retencion: retencion } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='mobile-view'>
                            { 
                                retenciones.map(
                                    (retencion, index) => {
                                        return(
                                            <>
                                                <Link className='certificado-visualizacion-link' key={index} to={{ pathname: "/portal/certificado-detalle", state: { tipo: tipo, retencion: retencion } }} ><TaxCard key={index} tax={retencion} isChecked1={isChecked1} isChecked2={isChecked2} /></Link>
                                            </>
                                        );
                                    }
                                )
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default CertificadoVisualizacionView;