
import { httpGet, httpGetError } from "../../http";
import { invoices } from '../../demo';

export const setInvoicesLoadingOnAction = () => (dispatch) => {
    dispatch({
        type: "SET_INVOICES_LOADING_ON"
    });
}

export const setInvoicesLoadingOffAction = () => (dispatch) => {
    dispatch({
        type: "SET_INVOICES_LOADING_OFF"
    });
}

export const getInvoicesDataAction = (co, dates) => async (dispatch, getState) => {

    dispatch({
        type: "SET_INVOICES_LOADING_ON"
    });

    try{

        const InvoicesReducer = getState().InvoicesReducer;
        const AppReducer = getState().AppReducer;

        dispatch({
            type: "SET_INVOICES_DATE1",
            payload: dates.date1
        });

        dispatch({
            type: "SET_INVOICES_DATE2",
            payload: dates.date2
        });

        //const resp = await httpGet('/api/v1/facturas?nit=' + AppReducer.supplier.nit + '&fecha1=' + dates.date1.replaceAll('-', '') + '&fecha2=' + dates.date2.replaceAll('-', ''));
        const resp = await httpGet('/api/v1/facturas/v2?nit=' + AppReducer.supplier.nit + '&co=' + co.codigo + '&fecha1=' + dates.date1.replaceAll('-', '') + '&fecha2=' + dates.date2.replaceAll('-', ''));
        dispatch({
            type: "SET_INVOICES_DATA",
            payload: resp.data
        });

    } catch (error) {
        console.log(error);        
        dispatch({
            type: "SET_ERROR",
            payload: {
                state: true,
                msg: 'Error.'
            }
        }); 
    } finally {
        dispatch({
            type: "SET_INVOICES_LOADING_OFF"
        });
    }
}

