import React, { useState, useEffect } from 'react';
import Title from '../../../components/title/Title';
import Button from '../../../components/button/Button';
import { formatNumber, formatNumberByDecimals } from '../../../misc';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './UploadDocs.css';

const XmarkCircleIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
    );
}


const FileStateIcon = ({ estado, msg }) => {
    return(
        <>
            { 
                (estado === 0) ?
                    <OverlayTrigger placement='bottom' overlay={
                        <Tooltip id='error'>
                        Preparado para enviar
                        </Tooltip>
                    }>
                        <div className='file-state-icon-container-upload'>
                            <FontAwesomeIcon className='view-icon' icon={faUpload} />
                        </div>
                    </OverlayTrigger>
                : (estado === 1) ?
                    <OverlayTrigger placement='bottom' overlay={
                        <Tooltip id='error'>
                        Enviado
                        </Tooltip>
                    }>
                        <div className='file-state-icon-container-uncheck'>
                            <FontAwesomeIcon className='view-icon' icon={faCheckCircle} />
                        </div>
                    </OverlayTrigger>
                : 
                    <OverlayTrigger placement='bottom' overlay={
                        <Tooltip id='error'>
                        Error
                        </Tooltip>
                    }>
                        <div className='file-state-icon-container-xmark'>
                            <XmarkCircleIcon />
                        </div>
                    </OverlayTrigger>
            }
        </>
    );
}

const UsedStorage = ({ storage }) => {
    return(
        <div className='upload-docs-used-storage-container'>
            <div className='upload-docs-used-storage'>Uso del espacio de almacenamiento</div>
            <div>{ formatNumberByDecimals(((storage.usedStorage / 1024) / 1024) / 1024, 4) } Gb / { formatNumber(((storage.totalStorage / 1024) / 1024) / 1024) } Gb</div>
            <div>{ formatNumber((storage.usedStorage * 100) / storage.totalStorage ) } %</div>
        </div>
    );
}


const DragDropFile = ({ onDrag, onDrop, onChange, dragActive }) => {
    return (
      <form id="upload-docs-form-file-upload" onDragEnter={onDrag} onSubmit={(e) => e.preventDefault()}>
        <input type="file" id="upload-docs-input-file-upload" multiple={true} onChange={onChange} />
        <label id="upload-docs-label-file-upload" htmlFor="upload-docs-input-file-upload" className={dragActive ? "drag-active" : ""} >
          <div>
            <p>Arrasta y suelta aquí los archivos a subir o</p>
            <span className="upload-docs-upload-button">Sube un archivo</span>
          </div> 
        </label>
        { dragActive && <div id="upload-docs-drag-file-element" onDragEnter={onDrag} onDragLeave={onDrag} onDragOver={onDrag} onDrop={onDrop}></div> }
      </form>
    );
  };

const TextField = ({ label, name, value, onChange }) => 
    <div className='textfield-container'>
        <div>{label}</div>
        <input type="text" name={name} value={value} onChange={onChange} />
    </div>

const UploadDocsView = ({ loading, onChange, onDrag, dragActive, onDrop, filesList, onSendClick, onCleanClick, storage, showSendButton }) => {
    return(
        <div className='page-container'>
            <Title>Subir soportes de documentos</Title>
            <div className='page-body'>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DragDropFile onDrag={onDrag} dragActive={dragActive} onDrop={onDrop} onChange={onChange} />
                    <UsedStorage storage={storage} />
                </div>
                { loading ? 
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                : filesList.length > 0 && 
                    <>
                        <br />
                        <br />
                        <div style={{ width: '300px', display: 'flex', gap: '10px', height: '40px' }}>
                            { showSendButton && <Button type="button" onClick={onSendClick} >Enviar</Button> }
                            <Button type="button" onClick={onCleanClick} >Limpiar</Button>
                        </div>
                        <br />
                        <table className='info-table'>
                            <thead>
                                <tr>
                                    <th>DOCUMENTO</th>
                                    <th>NOMBRE DE ARCHIVO</th>
                                    <th>TIPO</th>
                                    <th>TAMAÑO</th>
                                    <th>DETALLE</th>
                                    <th>ESTADO</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    filesList.map(
                                        (file, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{ file.documento }</td>
                                                    <td>{ file.file.name }</td>
                                                    <td>{ file.file.type }</td>
                                                    <td>{ formatNumber((file.file.size / 1024) / 1024) } Mb</td>
                                                    <td>{ file.detalle }</td>
                                                    <td><FileStateIcon estado={file.estado} /></td>
                                                </tr>
                                            );
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                    </>
                }
            </div>
        </div>
    );
}

export default UploadDocsView;