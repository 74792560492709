import React from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons'
import './CertificadosView.css';

const Certificado = (props) => {
    return(
        <div style={{ marginBottom: '20px' }}>
            <div className='certificado-container'>
                <div className='certificado-icon-container'>
                    <FontAwesomeIcon className='view-icon' icon={faAward} />
                </div>
                <div>
                    <div className='certificado-title'>{ props.children }</div>
                </div>
            </div>
        </div>
    );
}

const CertificadosView = ({ AppReducer }) => {

    return(
        <div className='page-container'>
            <Title>Certificados</Title>
            <br />
            <div className='page-body'>
                <div className='desktop-view'>
                    <SubTitle>Empresa: { AppReducer.company.name } </SubTitle>
                    <SubTitle>Proveedor:</SubTitle>
                </div>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ AppReducer.supplier.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ AppReducer.supplier.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Seleccione el tipo de certificado</SubTitle>
                <div>
                    <Row className='justify-content-md-center co-row'>
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/certificado-visualizacion', state: { tipo: { cuenta: '2365', descripcion: 'RETEFUENTE', ciudad: 'CALI', titulo: 'Certificado de Retención En La Fuente'  } } }}><Certificado>Retencion en la fuente</Certificado></Link>
                        </Col>
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/certificado-visualizacion', state: { tipo: { cuenta: '2368', descripcion: 'RETEFUENTE', ciudad: 'CALI', titulo: 'Certificado de Retención I.C.A.' } } }}><Certificado>Retencion I.C.A.</Certificado></Link>
                        </Col>
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/certificado-visualizacion', state: { tipo: { cuenta: '2367', descripcion: 'RETEIVA', ciudad: 'CALI', titulo: 'Certificado de Retención I.V.A.' } } }}><Certificado>Retencion I.V.A.</Certificado></Link>
                        </Col>
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/certificado-visualizacion', state: { tipo: { cuenta: '2335954', descripcion: 'ASOHOFRUCOL', ciudad: 'CALI', titulo: 'Certificado ASOHOFRUCOL' } } }}><Certificado>ASOHOFRUCOL</Certificado></Link>
                        </Col>   
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/certificado-visualizacion', state: { tipo: { cuenta: '2335957', descripcion: 'FEDEPAPA', ciudad: 'CALI', titulo: 'Certificado FEDEPAPA' } } }}><Certificado>FEDEPAPA</Certificado></Link>
                        </Col>   
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/certificado-visualizacion', state: { tipo: { cuenta: '2335955', descripcion: 'FENALCE 0.5%', ciudad: 'CALI', titulo: 'Certificado FENALCE' } } }}><Certificado>FENALCE 0.5%</Certificado></Link>
                        </Col>   
                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                            <Link to={{ pathname: '/portal/certificado-visualizacion', state: { tipo: { cuenta: '2335956', descripcion: 'FENALCE 0.75%', ciudad: 'CALI', titulo: 'Certificado FENALCE' } } }}><Certificado>FENALCE 0.75%</Certificado></Link>
                        </Col>   
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default CertificadosView;