import axios from 'axios';

const BE_URL = process.env.REACT_APP_ENV === 'prod' ? 'https://proveedores.rapimerque.com.co' : process.env.REACT_APP_ENV === 'test' ? 'http://api.aldetec.co/rapimerque' : 'https://proveedores.rapimerque.com.co';

export const httpGet = async (path) => {
   
    const options = {
        headers: { "Content-Type": "application/json" },
        timeout: 300000
    };
    
    let resp = await axios.get(BE_URL + path, options);

    return resp
}

export const httpPost = async (path, data) => {
    const options = {
        headers: { "Content-Type": "application/json" },
        timeout: 300000
    };
    
    let resp = await axios.post(BE_URL + path, data, options);
    return resp
}

export const httpPostFile = async (path, data) => {
    const options = {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 300000
    };
    
    let resp = await axios.post(BE_URL + path, data, options);
    return resp
}

export const httpPut = async (path, data) => {
    
    const options = {
        headers: { "Content-Type": "application/json" },
        timeout: 300000
    };
    
    let resp = await axios.put(BE_URL + path, data, options);
    return resp
}

export const httpDelete = async (path) => {
    
    const options = {
        headers: { "Content-Type": "application/json" },
        timeout: 300000
    };
    
    let resp = await axios.delete(BE_URL + path, options);
    return resp
}

export const httpGetError = (error) => {
    if (error instanceof axios.AxiosError){
        if(error.response.data){
            return error.response.data
        }
    } else {
        return {
            status: 500,
            code: 'INTERNAL_ERROR',
            msg: error.toString()
        }
    }
}

export const getFile = async (path) => {
    const res = await fetch(BE_URL + '/pdf/' + path);
    const blob = res.blob();
    return blob;
}

/*export const httpGetError = (err) => {
    let error = {
        errorCode: 0,
        error: false,
        errorMsg: ""
    };
    if(err.message.includes("Network Error")){
        error.errorCode = 0;
        error.error = true;
        error.errorMsg = "Ocurrio un error al conectar con el servidor.";
    } else{
        if(err.response){
            console.log(err.response);
            switch(err.response.status){
                case 400:
                    if(err.response.data.code === 1){
                        error.errorCode = err.response.data.code;
                        error.error = true;
                        error.errorMsg = '';
                    } else if(err.response.data.code === 2){
                        error.errorCode = err.response.data.code;
                        error.error = true;
                        error.errorMsg = '';
                    } else {
                        error.errorCode = err.response.status;
                        error.error = true;
                        error.errorMsg = "Error en la solicitud";    
                    }
                    break;
                case 404:
                    error.errorCode = err.response.status;
                    error.error = true;
                    error.errorMsg = "Recurso no encontrado";
                    break;
                case 500:
                    error.errorCode = err.response.status;
                    error.error = true;
                    error.errorMsg = "Ocurrio un error interno del servidor";
                    break;
                default:
                    error.errorCode = err.response.status;
                    error.error = true;
                    error.errorMsg = "Ha ocurrido un error al conectar con la api: " + err.response.status;
                    break;
            }
        }
    }
    return error;
}*/

