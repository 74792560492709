import React, { useState } from 'react';
import Title from '../../components/title/Title';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faMinusCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import DateField from '../../components/dateField';
import Button from '../../components/button/Button';
import StateIcon from '../../components/stateIcon/StateIcon';
import Card from '../../components/Card';

import './Facturas.css'



const InvoiceCard = ({ invoice }) => {
    return(
        <Card>
            <div className='card-fields-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>FECHA</div>
                    <div>{ formatDateMin(new Date(invoice.fechaDoc)) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CENTRO DE OPERACIÓN:</div>
                    <div className='card-field-data'>{ invoice.co }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DOCUMENTO:</div>
                    <div>{ invoice.docProv }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>VALOR BRUTO</div>
                    <div>${ formatNumber(invoice.totalBruto) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>VALOR NETO</div>
                    <div>${ formatNumber(invoice.totalNeto) }</div>
                </div>
            </div>
            <div className='card-icon-container'>
                <StateIcon estado={invoice.estado} />
            </div>
        </Card>
    );
}

const FacturasView = (props) => {

    const { dates, onChange, onClick, onExportClick, InvoicesReducer, onViewClick, onCardClick, co } = props;

   return(
    <div className='page-container'>
        <Title>Estado de Facturas { co.nombre }</Title>

        <div className='facturas-datefields-container'>
            <DateField label="Fecha inicial:" name='date1' value={dates.date1} onChange={onChange} /> 
            <DateField label="Fecha final:" name='date2' value={dates.date2} onChange={onChange} />
            <div className='facturas-button-container'><Button onClick={onClick}>Consultar</Button></div>
        </div>

        {InvoicesReducer.loading ?
            <div className='loading-spinner'>
                <Spinner animation="border" variant="primary" />
            </div>
        :
            <div className='page-body'>
                { InvoicesReducer.invoices.length > 0 &&
                <>
                    <br />
                    <div>
                        <button onClick={onExportClick}>Descargar Xlsx</button>
                    </div>
                </>
                }
                <div className='desktop-view'>
                    <table className='info-table'>
                    <thead>
                        <tr>
                            <th>NO.</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            <th>CENTRO DE OPERACIÓN</th>
                            <th>DOCUMENTO</th>
                            <th>DOCUMENTO DE CAUSACIÓN</th>
                            <th>VALOR BRUTO</th>
                            <th>VALOR NETO</th>
                            <th>ESTADO</th>
                            <th>VER</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            InvoicesReducer.invoices.map(
                                (factura, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ index + 1 }</td>
                                            <td>{ formatDateMin(new Date(factura.fechaDoc)) }</td>
                                            <td>{ factura.co }</td>
                                            <td>{ factura.docProv }</td>
                                            <td>{ factura.tipoDoc + '-' + factura.numDoc }</td>
                                            <td>${ formatNumber(factura.totalBruto) }</td>
                                            <td>${ formatNumber(factura.totalNeto) }</td>
                                            <td><StateIcon estado={factura.estado} /></td>
                                            <td>{ factura.estado && <div className='view-icon-container'><Link to={{ pathname: "" }} onClick={(e) => { props.onViewClick(e, factura) }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div>}</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                </div>
                <div className='mobile-view'>
                    <div className='cards-container'>
                        {
                        InvoicesReducer.invoices.map(
                            (invoice, index) => {
                                return(
                                    <>
                                        <Link to={{ pathname: "" }} onClick={(e) => { onViewClick(e, invoice) }}><InvoiceCard key={index} invoice={invoice} /></Link>
                                    </>
                                );
                            }
                        )
                        }
                    </div>
                </div>
            </div>
        }
    </div>
);
}

export default FacturasView;