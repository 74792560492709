import React from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { formatNumber, getCurrentDate, formatDateMin } from '../../misc';
import './CertificadoDetalle.css';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import Button from '../../components/button/Button';
import Card from '../../components/Card';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CertificadoPDFView from '../CertificadoPDF/CertificadoPDFView';

const Fecha = (props) => {
    return(
        <div className='fecha-container'>
            <div>{props.label}</div>
            <input type="date" id="fecha" name={props.name} value={props.value} onChange={props.onChange} />
        </div>
    );
}

const DetailCard = ({ detail }) => {
    return(
        <Card>
            <div className='card-fields-container egreso-detalle-card-fields-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>TOTAL RETENCIÓN:</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>BASE:</div>
                    <div>${ formatNumber(detail.total.base) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>RETENCIÓN:</div>
                    <div>${ formatNumber(detail.total.retencion) }</div>
                </div>
            </div>

        </Card>
    );
}

const CertificadoDetalleView = ({ AppReducer, history, request, retencion, tipo }) => {

    return(
        <div className='page-container'>
            <Title>{ history.location.state.tipo.titulo }</Title>
            
            <div className='page-body'>

                <br/>
                { 
                    request.loading ?
                        <div className='loading-spinner'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    : request.error ?
                        <div>
                            <Alert style={{ width: '100%', marginLeft: '40px', marginTop: '50px', marginBottom: '50px', textAlign: 'center' }} variant='success'>Ocurrio un error al cargar la información</Alert>
                        </div>
                    :
                    <>
                    <br/>
                    <br/>
                    <SubTitle>{ tipo.titulo } {AppReducer.company.name} </SubTitle>
                    <div>
                        <div>{AppReducer.company.name}</div>
                        <div>{AppReducer.company.id}-{AppReducer.company.vd}</div>
                    </div>
                    <br />
                    <div>
                        CALI
                    </div>
                    <div className='desktop-view'>         
                        <br />
                        <SubTitle>{ retencion.tipo.modo ? 'Periodo gravable:' : 'Año gravable:'} {retencion.tipo.periodoGravable}</SubTitle>
                        <div>
                            <div><span className='info-title'>Retenido: </span><span>{ retencion.proveedor.descripcion }</span></div>
                            <div><span className='info-title'>N.I.T.: </span><span>{ retencion.proveedor.codigo }-{ retencion.proveedor.digitoVerificacion }</span></div>
                            <div><span className='info-title'>Dirección </span><span>{ retencion.proveedor.direccion }</span></div>
                            <div><span className='info-title'>Ciudad </span><span>{ retencion.proveedor.ciudad }</span></div>
                            <br />
                            <br />
                            <div><span className='info-title'>Ciudad donde se practicó la retención: </span><span>{ tipo.ciudad }</span></div>
                            <div><span className='info-title'>Ciudad donde se consignó la retención: </span><span>{ tipo.ciudad }</span></div>
                        </div>
                        <br />
                        <table className='info-table tabla-retencion'>
                            <thead>
                                <tr>
                                    <th>CONCEPTO</th>
                                    <th>TASA</th>
                                    <th>BASE</th>
                                    <th>RETENCIÓN</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    retencion.data.map(
                                        (rete, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{ rete.descripcionCuenta }</td>
                                                    <td>{ rete.tasa }%</td>
                                                    <td>${ formatNumber(rete.base) }</td>
                                                    <td>${ formatNumber(rete.retencion) }</td>
                                                </tr>
                                            );
                                        }
                                    )
                                }
                                <tr>
                                    <td colSpan='2'>TOTAL</td>
                                    <td>${ formatNumber(retencion.total.base) }</td>
                                    <td>${ formatNumber(retencion.total.retencion) }</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <br />
                        <div>
                            <div className='retencion-footer-text'>Fecha de expedición { formatDateMin(new Date()) }</div>
                            < br/>
                            {/*<div className='retencion-footer-text'>Fecha de generación { getCurrentDate() }</div>
                            < br/>*/}
                            <div>
                                <span>Descargar </span>
                                <span className='pdf-icon-container'><Link style={{ color: 'red' }} to={{ pathname: '/portal/certificado-pdf', state: { retencion: retencion } }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></span>
                            </div>
                        </div>
                    </div>
                    <div className='mobile-view'>
                        {
                        <PDFDownloadLink document={<CertificadoPDFView retencion={retencion} />} fileName={ 'certificado.pdf' }>
                            {
                                ({ blob, url, loading, error }) => {
                                    return(loading ? 'Cargando informe...' : <div className='download-link pdf-icon-container'><FontAwesomeIcon className='view-icon' icon={faFilePdf} /> Descargar</div>);
                                }
                            }
                        </PDFDownloadLink>
                        }
                        <br />
                        <div className='cards-container'>
                            <DetailCard detail={retencion} />
                        </div>
                    </div>
                    </>
                }
            </div>         
        </div>
    );
}

export default CertificadoDetalleView;
