import React from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { Row, Col } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CentrosOperacion.css';

const Co = (props) => {
    return(
        <div style={{ marginBottom: '20px' }}>
            <div className='co-container'>
                <div className='co-icon-container'>
                    <FontAwesomeIcon className='co-icon' icon={faStore} />
                </div>
                <div className='co-name-container'>
                    <div>
                        <span className='co-title'>CODIGO: </span><span>{ props.co.codigo }</span>
                    </div>
                    <div>
                        <span className='co-title'>NOMBRE: </span><span>{ props.co.nombre }</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

const CentrosOperacionView = ({ AppReducer, co, path }) => {

    return(
        <div className='page-container'>
            <Title>Centros de operación</Title>
            <div className='page-body'>
                <br />
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ AppReducer.supplier.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ AppReducer.supplier.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <div className='desktop-view'>    
                    <SubTitle>Seleccione el centro de operacion</SubTitle>
                    { co.loading ?
                    <div className='loading-spinner'>
                            <Spinner animation="border" variant="primary" />
                    </div>
                    :
                    <div>
                        <Row className='justify-content-md-center co-row'>
                        {
                            co.data.map(
                                centro => {
                                    return(
                                        <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                                            <Link to={{ pathname: path, state: { co: centro } }}><Co co={centro} /></Link>
                                        </Col>
                                    );
                                }
                            )
                        }
                        </Row>
                    </div>
                    }
                </div>
                <div className='mobile-view'>
                    { co.loading ?
                        <div className='loading-spinner'>
                                <Spinner animation="border" variant="primary" />
                        </div>
                        :
                        <div>
                            <Row className='justify-content-md-center co-row'>
                            {
                                co.data.map(
                                    centro => {
                                        return(
                                            <Col className='co-col' xs={12} md={6} lg={6} xl={4} >
                                                <Link to={{ pathname: path, state: { co: centro } }}><Co co={centro} /></Link>
                                            </Col>
                                        );
                                    }
                                )
                            }
                            </Row>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CentrosOperacionView;
