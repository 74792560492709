import React, { useState, useEffect } from 'react';
import { Navbar, Alert } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AdminHome from './AdminHome';
import Proveedores from '../proveedores/Proveedores';
import UploadDocs from '../UploadDocs';
import CentrosOperacion from '../centrosOperacion/CentrosOperacion';
import Profile from '../profile/Profile';
import Admins from '../admins/Admins';
import Lapsos from '../lapsos/Lapsos';
import Storage from '../Storage';
import logo from '../../../img/logo.png';
import './AdminLayout.css'

const MenuOption = (props) =>  {
    return(
        <div className='menu-option-wrapper'>
            <div className='menu-icon-container' onMouseOver={ props.onMouseOver } onMouseLeave={ props.onMouseLeave }>
                <Link style={{ color: 'white' }} to={props.to}><FontAwesomeIcon className='menu-icon' icon={props.icon} /></Link>
            </div>
            <div className={props.className}>{props.children}</div>
        </div>
    );
}

const AdminLayoutView = ({AppReducer, AdminReducer, menu, onMouseOver, onMouseLeave, onExit, history}) => {

    return(
        <div className="admin-layout-container">
            <Navbar fixed="top" bg="dark" variant="dark" collapseOnSelect expand="md" style={{ height: '40px', justifyContent: 'space-between' }}> 
                <Navbar.Brand className="brand-title" href="#home">Portal de administración</Navbar.Brand>
                <Navbar.Text>Hola { AdminReducer.admin.nombre } <a href="#" onClick={onExit}>Salir</a></Navbar.Text>
            </Navbar>
            
            <div className="admin-layout-desktop-view">
                <div className='home-wrapper'>
                    <div className={menu.menuClass} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} >
                        <div className='menu-logo-wrapper'>
                        </div>
                        <MenuOption className={menu.menuOptionText} icon={faUsers} to='/admin/proveedores'>Proveedores</MenuOption>
                        <MenuOption className={menu.menuOptionText} icon={faUpload} to='/admin/subir-documentos'>Subir documentos</MenuOption>
                        <MenuOption className={menu.menuOptionText} icon={faStore} to='/admin/centros-operacion'>Configuracion centros de operación</MenuOption>
                        <MenuOption className={menu.menuOptionText} icon={faUsersCog} to='/admin/admins'>Administradores</MenuOption>
                        <MenuOption className={menu.menuOptionText} icon={faUserCircle} to='/admin/profile'>Perfil</MenuOption>
                    </div>
                </div>

                <div className='admin-logo-container'>
                    <img className='admin-logo' src={logo} />
                </div>
                
                { AppReducer.company.dueWarning &&
                    <div className='admin-warning-container'>
                        <Alert variant='danger'>Estimado cliente, aun no hemos registrado su pago, tendrá servicio hasta al 30 de junio de 2023, por favor realice su pago para seguir disfrutando del servicio.</Alert>
                    </div>
                }

                <div className='home-main'>
                    <Switch>
                        <Route path="/admin/profile" render={() => (<Profile history={history} />)} />
                        <Route path="/admin/admins" render={() => (<Admins history={history} />)} />
                        <Route path="/admin/subir-documentos" render={() => (<UploadDocs history={history} />)} />
                        <Route path="/admin/periodos-reteiva" render={() => (<Lapsos history={history} />)} />
                        <Route path="/admin/centros-operacion" render={() => (<CentrosOperacion history={history} />)} />
                        <Route path="/admin/proveedores" render={() => (<Proveedores history={history} />)} />
                        <Route path="/admin/almacenamiento" render={() => (<Storage history={history} />)} />
                        <Route path="/admin" render={() => (<AdminHome history={history} />)} />
                    </Switch>
                </div>
            </div>
            <div className="admin-layout-mobile-view">
                <Alert variant='warning'>El area de administración del portal solo esta disponible en el versión de escritorio.</Alert>
            </div>
        </div>
    );
}

export default AdminLayoutView;