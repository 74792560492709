import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faListUl } from '@fortawesome/free-solid-svg-icons';
import Home from '../Home';
import Egresos from '../Egresos';
import EgresoDetalle from '../EgresoDetalle';
import DocumentoDetalle from '../DocumentoDetalle';
import DocumentoCMDetallePDF from '../DocumentoCMDetallePDF';
import NotasComercial from '../NotasComercial';
import EgresoPDF from '../EgresoPDF';
import Certificados from '../Certificados';
import CertificadoDetalle from '../CertificadoDetalle';
import CertificadoPDF from '../CertificadoPDF';
import Profile from '../Profile';
import DocumentosCruzados from '../documentosCruzados/DocuementosCruzados';
import DocumentoCruzadoDetalle from '../documentosCruzados/DocumentoCruzadoDetalle';
import DocumentoCruzadoPDF from '../documentosCruzados/DocumentoCruzadoPDF';
import OtrosDocumentos from '../OtrosDocumentos/OtrosDocumentos';
import OtrosDocumentosDetalle from '../OtrosDocumentos/OtrosDocumentosDetalle';
import DocumentoPDF from '../OtrosDocumentos/DocumentoPDF';
import Facturas from '../Facturas';
import DetalleSaldo from '../DetalleSaldo';
import Reports from '../Reports';
import CausacionDetalle from '../CausacionDetalle';
import DocumentoComercialDetalle from '../DocumentoComercialDetalle';
import { Link } from 'react-router-dom';
import './HomeLayout.css';
import logo from '../../img/logo.png';
import Menu from '../../components/Menu';
import CentrosOperacion from '../CentrosOperacion';
import NotasFinanciero from '../NotasFinanciero';
import DocumentoFinancieroDetalle from '../DocumentoFiancieroDetalle';
import DocumentoFIDetallePDF from '../DocumentoFIDetallePDF';
import CertificadoVisualizacion from '../CertificadoVisualizacion';


const MenuIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path id="lineas" d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
    );
}

const HomeLayoutView = ({ AppReducer, onClickLegend, tiposDocumento, showLegend, exit, onBurgerButtonClick, history, onMenuOptionClick }) => {

    return(
        <div>
            <Navbar fixed="top" bg="dark" variant="dark" collapseOnSelect expand="md" style={{ height: '40px', justifyContent: 'space-between' }}> 
                <div className="brand-title">
                    <Navbar.Brand  href="#home">Portal para proveedores - { AppReducer.company.name }</Navbar.Brand>
                </div>
                <div className="nav-username">
                    <Navbar.Text>Hola { AppReducer.supplier.razonSocial } <a href="#" onClick={exit}>Salir</a></Navbar.Text>
                </div>
                <div className='burger-button-container'>
                    <Link className="burger-button" to={{ pathname: '' }} onClick={ onBurgerButtonClick } >
                        <MenuIcon />
                    </Link>
                </div>
            </Navbar>
        
        
            <div className='home-wrapper'>
                <Menu onMenuOptionClick={onMenuOptionClick} history={history} />
            </div>

            <div className={ showLegend ? "legend-container-active" : "legend-container"}>
                <button className="legend-button" onClick={onClickLegend} >
                    <FontAwesomeIcon icon={faListUl} />
                </button>
            </div>

            <div className='home-logo-container'>
                <img className='home-logo' src={ logo } />
            </div>

            <div className='home-main'>
                <Switch>
                    <Route path="/portal/egresos" render={() => (<Egresos history={history} />)} />
                    <Route path="/portal/egreso-detalle" render={() => (<EgresoDetalle history={history} />)} />
                    <Route path="/portal/documento-detalle" render={() => (<DocumentoDetalle history={history} />)} />
                    <Route path="/portal/notas-cm" render={() => (<NotasComercial history={history} />)} />
                    <Route path="/portal/egreso-pdf" render={() => (<EgresoPDF history={history} />)} />
                    <Route path="/portal/centros-operacion" render={() => (<CentrosOperacion history={history} />)} />
                    <Route path="/portal/certificados" render={() => (<Certificados history={history} />)} />
                    <Route path="/portal/certificado-detalle" render={() => (<CertificadoDetalle history={history} />)} />
                    <Route path="/portal/certificado-pdf" render={() => (<CertificadoPDF history={history} />)} />
                    <Route path="/portal/Profile" render={() => (<Profile history={history} />)} />
                    {/*<Route path="/portal/certificado-ciudades" render={() => (<CertificadoCiudades history={history} />)} />*/}
                    <Route path="/portal/documentos-cruzados" render={() => (<DocumentosCruzados history={history} />)} />
                    <Route path="/portal/documento-cruzado-detalle" render={() => (<DocumentoCruzadoDetalle history={history} />)} />
                    <Route path="/portal/documento-cruzado-pdf" render={() => (<DocumentoCruzadoPDF history={history} />)} />
                    <Route path="/portal/otros-documentos" render={() => (<OtrosDocumentos history={history} />)} />
                    <Route path="/portal/otros-documentos-detalle" render={() => (<OtrosDocumentosDetalle history={history} />)} />
                    <Route path="/portal/documento-pdf" render={() => (<DocumentoPDF history={history} />)} />
                    <Route path="/portal/documento-fi-detalle-pdf" render={() => (<DocumentoFIDetallePDF history={history} />)} />
                    <Route path="/portal/documento-cm-detalle-pdf" render={() => (<DocumentoCMDetallePDF history={history} />)} />
                    <Route path="/portal/facturas" render={() => (<Facturas history={history} />)} />
                    <Route path="/portal/detalle-saldo" render={() => (<DetalleSaldo history={history} />)} />
                    <Route path="/portal/reportes" render={() => (<Reports history={history} />)} />
                    <Route path="/portal/causacion-detalle" render={() => (<CausacionDetalle history={history} />)} />
                    <Route path="/portal/documento-comercial-detalle" render={() => (<DocumentoComercialDetalle history={history} />)} />
                    <Route path="/portal/notas-fi" render={() => (<NotasFinanciero history={history} />)} />
                    <Route path="/portal/documento-financiero-detalle" render={() => (<DocumentoFinancieroDetalle history={history} />)} />
                    <Route path="/portal/certificado-visualizacion" render={() => (<CertificadoVisualizacion history={history} />)} />
                    <Route path="/portal" render={() => (<Home history={history} />)} />
                </Switch>
            </div>

        </div>
    );
}

export default HomeLayoutView
