import React, { useState, useEffect } from 'react';
import UploadDocsView from './UploadDocsView';
import { documents } from '../../../demo';
import axios from 'axios';
import { httpGet, httpPost, httpPostFile } from '../../../http';

const UploadDocs = () => {

    const [ form, setForm ] = useState({
        idCo: '',
        tipoDoc: '',
        numeroDoc: ''
    });
    
    const [ document, setDocument ] = useState({
        idCo: '',
        tipoDoc: '',
        numeroDoc: '',
        valor: 0
    });

    const [ loading, setLoading ] = useState(false);

    const [ showFiles, setShowFiles ] = useState(false);

    const [ showSendButton, setShowSendButton ] = useState(true);

    const [dragActive, setDragActive] = useState(false);

    const [ filesList, setFilesList ] = useState([]);

    const [ storage, setStorage ] = useState({
        usedStorage: 0,
        totalStorage: 0
    });

    useEffect(() => {
        getStorage();
    }, []);
  
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const getStorage = async () => {
        try {
            const resp = await httpGet('/api/v1/upload/storage');
            if (resp) {
                setStorage(resp.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = async () => {

        setLoading(true);

        setDocument({
            idCo: '',
            tipoDoc: '',
            numeroDoc: '',
            valor: 0
        });

        const doc = documents.find((element) => {
            return element.idCo === form.idCo && element.tipoDoc === form.tipoDoc && element.numeroDoc === form.numeroDoc
        });
        console.log(documents);
        console.log('DOC', doc);

        if (doc) {
            setDocument({
                ...document,
                idCo: doc.idCo,
                tipoDoc: doc.tipoDoc,
                numeroDoc: doc.numeroDoc,
                valor: doc.valor
            });

            setForm({
                ...form,
                idCo: '',
                tipoDoc: '',
                numeroDoc: ''
            });
        }        
        setLoading(false);
    }

    /*const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }*/

    const handleAddClick = () => {
        setShowFiles(true);
    }

    const processFiles = async (files) => {
        setLoading(true);
        setDragActive(false);
        let newFilesList = [...filesList];
        if (files && files[0]) {
            let remainingStorage = storage.totalStorage - storage.usedStorage;
            for(const file of files) {
                if(file.name.length >= 11){
                    const co = file.name.substring(0, 3);
                    const tipoDoc = file.name.substring(3, 5);
                    const numDoc = file.name.substring(5, 11);
                    let detalle = 'OK'
                    let estado = 0;

                    if (file.size > 1048576) {
                        detalle = 'SE SUPERÓ EL TAMAÑO PERMITIDO';
                        estado = 2
                    }
                    
                    if (file.type !== 'application/pdf') {
                        detalle = 'TIPO DE ARCHIVO NO PERMITIDO';
                        estado = 2
                    }

                    if(!await validarDocumento(co, tipoDoc, numDoc)) {
                        detalle = 'DOCUMENTO NO ENCONTRADO';
                        estado = 2
                    }

                    if (file.size > remainingStorage) {
                        detalle = 'ESPACIO DE ALMACENAMIENTO AGOTADO';
                        estado = 2
                    } else {
                        remainingStorage = remainingStorage - file.size;
                    }

                    newFilesList.push({
                        documento: co + '-' + tipoDoc + '-' + numDoc ,
                        detalle: detalle,
                        estado: estado,
                        file: file
                    });
                }
            }

            setFilesList(newFilesList);
            setLoading(false);
        }
    }

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await processFiles(e.dataTransfer.files);
        // at least one file has been dropped so do something
        // handleFiles(e.dataTransfer.files);
    }

    const handleChange = async (e) => {
        e.preventDefault();
        await processFiles(e.target.files);
    };

    const validarDocumento = async (co, tipoDoc, numDoc) => {
        let r = false;
        try {
            //const strNombreDocumento = file.name.substring(0,10);
            const resp = await httpGet('/api/v1/soportes/validar/' + co + '/' + tipoDoc + '/' + numDoc);
            if (resp.data) {
                r = true;
            }
        } catch (error) {
            if (error instanceof axios.AxiosError){
                console.log('CODE', error.response.status);
                if((error.response.status === 400)){
                    r = false;
                }
            }
        }
        
        return r;
    }
    
    const handleSend = async () => {
        setLoading(true);
        const newFiles = [];
            for(const file of filesList) {
                try {
                    console.log('FILE', file);
                    if(file.estado === 0) {
                        console.log('SEND');
                        let data = new FormData();
                        data.append('file', file.file)
                        const resp = await httpPost('/api/v1/upload', data);
                        if (resp.status === 200) {
                            newFiles.push({...file, estado: 1});
                        }
                    } else {
                        newFiles.push({...file});
                    }
                } catch (error) {
                    newFiles.push({...file, estado: 2});
                }
            }      
        setFilesList(newFiles);
        await getStorage();
        setShowSendButton(false);
        setLoading(false);
    }

    const handleClean = () => {
        setShowSendButton(true);
        setFilesList([]);
    }

    return(
        <UploadDocsView 
            loading={loading} 
            form={form} 
            document={document} 
            showFiles={showFiles} 
            onClick={handleClick} 
            onChange={handleChange} 
            onAddClick={handleAddClick} 
            onDrag={handleDrag} 
            dragActive={dragActive} 
            onDrop={handleDrop}
            filesList={filesList}
            onSendClick={handleSend}
            onCleanClick={handleClean}
            storage={storage}
            showSendButton={showSendButton}
        />
    );
}

export default UploadDocs;